<template>
    <div class="flex flex-col justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 md:px-32 lg:px-32">
        
        <router-link :to="{name:'ProfilUbah'}" class="flex items-center flex-row py-2 px-3 my-1 cursor-pointer">
            <div class="h-16 w-16 bg-gray-100 rounded-lg content-center flex items-center text-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 mx-auto text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
            </div>
            <div class="flex-grow ml-3 pt-1 pb-1">
                <h2 class="font-semibold tracking-tight text-gray-800 text-lg">Pengaturan Akun</h2>
            </div>
        </router-link>

        <router-link :to="{name:'ProfilPassword'}" class="flex items-center flex-row py-2 px-3 my-1 cursor-pointer">
            <div class="h-16 w-16 bg-gray-100 rounded-lg content-center flex items-center text-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 mx-auto text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
                </svg>
            </div>
            <div class="flex-grow ml-3 pt-1 pb-1">
                <h2 class="font-semibold tracking-tight text-gray-800 text-lg">Ubah Password</h2>
            </div>
        </router-link>

        <div @click="logoutConfirm()" class="flex items-center flex-row py-2 px-3 my-1 cursor-pointer">
            <div class="h-16 w-16 bg-red-100 rounded-lg content-center flex items-center text-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 mx-auto text-red-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                </svg>
            </div>
            <div class="flex-grow ml-3 pt-1 pb-1">
                <h2 class="font-semibold tracking-tight text-gray-800 text-lg">Logout</h2>
            </div>
        </div>

        <modal :isOpen="modalConfirmLogout">
            <div class="md:flex items-center">
                <div class="bg-red-200 rounded-full flex items-center justify-center w-16 h-16 flex-shrink-0 mx-auto">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                    </svg>
                </div>
                <div class="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                    <p class="font-bold">Keluar Aplikasi</p>
                    <p class="text-sm text-gray-800 mt-1">
                        Apakah anda yakin akan logout dari aplikasi ini?
                    </p>
                </div>
            </div>

            <div class="text-center md:text-right mt-4 md:flex md:justify-end" >
                <button @click="logoutAction()" class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-blue-200 text-blue-700 rounded-lg font-semibold text-sm md:ml-2 md:order-2">
                    Logout
                </button>
                <button @click="modalConfirmLogout = false" class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-gray-200 rounded-lg font-semibold text-sm mt-4 md:mt-0 md:order-1">Batal</button>
            </div>
        </modal>

    </div>
</template>
<script>
    import Modal from '../../components/Modal.vue';
import { showSuccessNotif } from '../../helpers/Tools';
    export default {
        name: 'ProfilIndex',
        components : {
            Modal
        },
        data() {
            return {
                modalConfirmLogout : false
            }
        },
        methods : {
            logoutConfirm () {
                this.modalConfirmLogout = true;
            },
            logoutAction () {
                this.$store.dispatch('actionLoginUser', null);
                setTimeout(() => {
                    this.$router.replace({
                        path: '/'
                    });
                    showSuccessNotif(this, "Logout berhasil dilakukan");
                }, 100);
            }
        }
    }
</script>
